import { lib } from '..';
import {
  arrayToObjectValuesList,
  CHAT_TYPES,
  REGISTRATION_MATCH_STATUS,
  FEATURE_STATUS,
  POOL_STATUS,
  ATTENDEE_STATUS_NOT_THERE,
} from '../library';
import { arrayToCount, arrayToObject, arrayToObjectList, arrayToObjectValues } from '../library';

export const constructProfilesFromAPI = (profileData, relations) => {
  // const d_userTopics = arrayToObjectList(profileData.topics, 'user_id');
  const userRelations = relations ? arrayToObject(relations.profile_relations, 'user_to_id') : {};
  const profiles = profileData.profiles.map(profile => ({
    ...profile,
    // user_topics: d_userTopics[profile.id] || [],
    user_relation: userRelations[profile.id] || null,
  }));
  return { profiles, count: profileData.count };
};

export const constructEventsFromAPI = (eventData, relations) => {
  const d_attendees = arrayToObjectList(eventData.attendees, 'event_id');
  // const d_locations = arrayToObject(eventData.locations, 'id');
  const profile_data = constructProfilesFromAPI(eventData.profile_data, relations);
  const d_profiles = arrayToObject(profile_data.profiles, 'id');

  const event_applys_with_profile = (eventData.event_requests || []).map(ea => {
    return { ...ea, profile: d_profiles[ea.user_id] };
  });
  const d_hostingEventLikes = arrayToObjectList(event_applys_with_profile, 'event_id');

  const d_attendeeCurrents = !relations ? {} : arrayToObject(relations.attendee_currents, 'event_id');
  const d_eventLikes = !relations ? {} : arrayToObject(relations.event_requests, 'event_id');
  // const d_userRegs = !relations ? {} : arrayToObject(relations.proposal_registrations, 'event_id');

  const events = eventData.events.map(event => {
    const attendeesSorted = d_attendees[event.id]
      ? d_attendees[event.id].sort((a, b) => {
          // Sorted by: 1. is primary host, 2. is host, 3. is attendee
          if (a.user_id === event.primary_host_id) return -1;
          if (b.user_id === event.primary_host_id) return 1;
          if (a.is_host) return -1;
          if (b.is_host) return 1;
          return 0;
        })
      : [];

    const attendeeCurrent = d_attendeeCurrents[event.id] || null;
    const isReviewNeeded =
      event.event_status === lib.EVENT_STATUS.DONE &&
      attendeeCurrent?.attendee_status === lib.ATTENDEE_STATUS.ATTENDING;

    console.log('WAT');
    if (event.id === 899) {
      console.log(
        'EE',
        event.id,
        attendeesSorted,
        attendeesSorted
          .filter(a => !ATTENDEE_STATUS_NOT_THERE.includes(a.attendee_status))
          .map(a => d_profiles[a.user_id]),
      );
      console.log({ d_profiles });
    }

    return {
      // location: d_locations[event.location_id] || null,
      ...event,
      primaryHost: (event.primary_host_id && d_profiles[event.primary_host_id]) || null,
      attendees: arrayToObject(d_attendees[event.id], 'user_id'),
      attendeeCurrent,
      isReviewNeeded,
      profilesAll: attendeesSorted.map(a => d_profiles[a.user_id]).filter(a => a !== undefined),
      profiles: attendeesSorted
        .filter(a => !ATTENDEE_STATUS_NOT_THERE.includes(a.attendee_status))
        .map(a => d_profiles[a.user_id])
        .filter(p => p !== undefined),
      eventRequest: d_eventLikes[event.id] || null,
      hostingEventLikes: d_hostingEventLikes[event.id] || [],
      // userTicket: d_userRegs[event.id] || null,
    };
  });

  return events;
};

export const constructCityUpdatesFromAPI = (updateData, relations) => {
  if (updateData?.updates) {
    const profile_data = constructProfilesFromAPI(updateData.profile_data, relations);
    const d_profiles = arrayToObject(profile_data.profiles, 'id');
    const updates = updateData.updates;
    updates.forEach(update => (update.profile = d_profiles[update.user_id] || null));
    return updates;
  }
  return [];
};

// export const constructActivityFromAPI = (activityData, relations) => {
//   const profile_data = constructProfilesFromAPI(activityData.profile_data, relations);
//   const events = constructEventsFromAPI(activityData.events_data, relations);
//   const d_profiles = arrayToObject(profile_data.profiles, 'id');
//   const d_locations = arrayToObject(activityData.locations, 'id');
//   const d_events = arrayToObject(events, 'id');
//   const activities = activityData.activity;
//   activities.forEach(item => {
//     if (item.model_name === 'user') {
//       item.profile = d_profiles[item.object_id];
//     } else if (item.model_name === 'location') {
//       item.location = d_locations[item.object_id];
//     } else if (item.model_name === 'event') {
//       item.event = d_events[item.object_id];
//     }
//   });
//   return activities;
// };

export const constructLeaderboardFromAPI = data => {
  let tagProfiles = {};
  Object.keys(data.leaderboards).forEach(name => {
    const profileData = data.leaderboards[name];
    tagProfiles[name] = constructProfilesFromAPI(profileData, data.relations).profiles;
  });
  return tagProfiles;
};

export const constructCityManagersFromAPI = data => {
  const { city_managers, profiles } = data;
  const managers = city_managers.map(cm => {
    const profile = profiles.find(user => user.id === cm.user_id);
    return { ...cm, profile };
  });
  return managers;
};

export const constructInvitesFromAPI = data => {
  const profile_data = constructProfilesFromAPI(data.profile_data, data.relations);
  const d_profiles = arrayToObject(profile_data.profiles, 'id');
  const d_couple_data = arrayToObject(data.pair_registration_data, 'user_id');
  const invites = data.invites.map(invite => {
    return {
      ...invite,
      fromProfile: d_profiles[invite.user_from_id] || null,
      toProfile: d_profiles[invite.user_to_id] || null,
      fromName: d_profiles[invite.user_from_id]?.full_name || (invite.user_from_id ? 'Someone' : 'SocialTable'),
      coupleData: d_couple_data[invite.user_from_id] || d_couple_data[invite.user_to_id] || null,
      isFeatureActive: invite.feature_status === FEATURE_STATUS.ACTIVE,
      isFeatureAccepted: invite.feature_status === FEATURE_STATUS.ACCEPTED,
    };
  });

  return invites;
};

export const constructTestimonialsFromAPI = data => {
  const profile_data = constructProfilesFromAPI(data.profile_data, []);
  const d_profiles = arrayToObject(profile_data.profiles, 'id');
  const testimonials = data.testimonials.map(test => ({
    ...test,
    profile: d_profiles[test.user_id],
  }));
  return testimonials;
};

export const constructAppreciationsFromAPI = (apprData, relations) => {
  const profile_data = constructProfilesFromAPI(apprData.profile_data, relations);
  const d_profiles = arrayToObject(profile_data.profiles, 'id');
  const appreciations = apprData.appreciations.map(ap => ({
    ...ap,
    profileFrom: d_profiles[ap.user_from],
    profileTo: d_profiles[ap.user_to],
  }));

  return appreciations;
};

export const constructReviewsFromAPI = reviewData => {
  const profile_data = constructProfilesFromAPI(reviewData.profile_data, reviewData.relations);
  const d_profiles = arrayToObject(profile_data.profiles, 'id');
  const reviews = reviewData.reviews.map(rev => ({
    ...rev,
    profileFrom: d_profiles[rev.user_id],
  }));

  return reviews;
};

export const constructContactsDataFromAPI = data => {
  console.log({ data });
  const { profile_data, friend_counts, relations } = data;
  const { profiles } = constructProfilesFromAPI(profile_data, relations);
  const d_profiles = arrayToObject(profiles, 'phone');
  const d_friendCounts = Object.fromEntries(friend_counts);

  // console.log({ friend_counts, d_friendCounts });

  return {
    d_profiles,
    d_friendCounts,
  };
};

// export const constructProfileExtras = data => {
//   const locations = sortRestaurants(data.locations);
//   const peopleMetData = constructProfilesFromAPI(data.people_met_data, data.relations);
//   const events = constructEventsFromAPI(data.events_data, data.relations);
//   const appreciations = constructAppreciationsFromAPI(data.appreciations_data, data.relations);
//   const returnData = { locations, events, peopleMet: peopleMetData.profiles, appreciations };
//   return returnData;
// };

export const constructNewCompliments = data => {
  const totalCount = data.length;
  const tagCountObj = arrayToCount(data);
  const tagCounts = Object.entries(tagCountObj)
    .map(([tagId, count]) => ({ tagId, count }))
    .sort((a, b) => b.count - a.count);
  return { totalCount, tagCounts };
};

// export const processUsersFromAPI = (user, result) => {
//     const relations = arrayToObject(result.relations, "user_to_id");
//     // NOTE: this could be done server side instead
//     const profile_groups =  arrayToObjectList(result.profile_groups, "user_id");
//     let profiles = result.profiles.filter(p => p.id != user.id);
//     profiles = profiles.map(p=>({...p, user_topics: profile_groups[p.id]}));
//     return {profiles, relations};
// };

export const sortRestaurants = restaurants => {
  return restaurants.sort(l => (l.image_url ? -1 : 1));
};

export const transformProfiles = response => {
  return constructProfilesFromAPI(response.profile_data, response.relations);
};

export const transformAppData = response => {
  return {
    data: response.data,
    cuisine: arrayToObject(response.cuisine, 'id'),
    cuisineIds: response.cuisine.map(c => c.id),
    tags: arrayToObject(response.tags, 'id'),
    d_attributes: arrayToObject(response.attributes, 'id'),
    compliments: response.tags.filter(tag => tag.is_compliment),
    negative_tags: response.tags.filter(tag => !tag.is_compliment),
  };
};

export const transformUserData = response => {
  return {
    // d_userTopics: arrayToObject(response.user_topics, 'id'),
    userCuisine: arrayToObjectValues(response.cuisine, 'cuisine_id', 'level'),
    userGoals: arrayToObjectValues(response.goals, 'goal_id', 'level'),
    paymentMethods: response.payment_methods,
    eventPrefs: response.event_prefs_base,
    currencyPrefs: arrayToObject(response.currency_prefs, 'currency'),
    bells: response.bells,
  };
};
