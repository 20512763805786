import { apiSlice } from './apiSlice';

export const apiAuth = apiSlice.injectEndpoints({
  endpoints: build => ({
    sendWhatsappCode: build.mutation({
      query: body => ({ url: `/auth/send_whatsapp_code`, method: 'PUT', body }),
    }),
    updateFirebaseToken: build.mutation({
      query: body => ({ url: `/auth/update_firebase_token`, method: 'PUT', body }),
    }),
    loginWithUsername: build.mutation({
      query: body => ({ url: `/auth/login_with_username`, method: 'PUT', body }),
    }),
    loginWithKey: build.mutation({
      query: body => ({ url: `/auth/login_with_key`, method: 'PUT', body }),
    }),
    loginWithPhone: build.mutation({
      query: body => ({ url: `/auth/login_with_phone`, method: 'PUT', body }),
    }),
    loginWithWhatsapp: build.mutation({
      query: body => ({ url: `/auth/login_with_whatsapp`, method: 'PUT', body }),
    }),
    testLoginAsNewUser: build.mutation({
      query: body => ({ url: `/auth/test_login_as_new_user`, method: 'PUT', body }),
    }),
    deleteAccount: build.mutation({
      query: body => ({ url: `/auth/delete_account`, method: 'PUT', body }),
    }),
  }),
  overrideExisting: true,
});

export const {
  useSendWhatsappCodeMutation,
  useLoginWithUsernameMutation,
  useLoginWithKeyMutation,
  useLoginWithPhoneMutation,
  useLoginWithWhatsappMutation,
  useTestLoginAsNewUserMutation,
  useUpdateFirebaseTokenMutation,
  useDeleteAccountMutation,
} = apiAuth;
