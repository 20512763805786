import { api, lib } from '@tlsocial/common';
import { useSelector } from 'react-redux';
import { useUniversalEvent } from './useUniversalEvent';

const calcLivePoolStatus = (pool, isPassedRegistrationDeadline) => {
  if (pool) {
    if (isPassedRegistrationDeadline) {
      if (pool.status === lib.POOL_STATUS.OPEN) {
        return lib.POOL_STATUS.MATCHING;
      }
    }
    return pool.status;
  }
};

export const usePool = (pool, city) => {
  const { user } = lib.useUser();

  const invitesData = usePairInvites(pool, city);
  const xUniversal = useUniversalEvent(pool);
  const cetData = lib.useCityEventType(city, pool?.event_type);

  if (!pool) return null;

  const liveStatus = calcLivePoolStatus(pool, xUniversal.isPassedRegistrationDeadline);
  const cityTimeMatching = lib.toZoned(pool.time_matching, pool.city_timezone);
  const typeData = lib.EVENT_TYPE_DATA[pool.event_type];

  // const poolPhotoSizes = pool.series && lib.getPhotoSizes(pool.series);

  const isRegistrationConfirmed =
    xUniversal.registration?.registration_status === lib.POOL_REGISTRATION_STATUS.CONFIRMED;

  return {
    pool,
    ...xUniversal,
    ...invitesData,
    ...cetData,
    // poolPhotoSizes,
    typeData,
    locationText: pool.location_text || cetData?.locationText,
    cityTimeMatching,
    liveStatus,
    isOpen: liveStatus === lib.POOL_STATUS.OPEN,
    isMatching: liveStatus === lib.POOL_STATUS.MATCHING,
    isMatched: liveStatus === lib.POOL_STATUS.MATCHED,
    isCancelled: liveStatus === lib.POOL_STATUS.CANCELLED,
    isRegistrationConfirmed,
  };
};

const deduplicateInvites = invites => {
  const seenIds = new Set();
  return invites.filter(invite => {
    const isDuplicate = seenIds.has(invite.id);
    seenIds.add(invite.id);
    return !isDuplicate;
  });
};

export const usePairInvites = (pool, city) => {
  const { user } = lib.useUser();
  const inviteLastOpened = useSelector(state => state.local.inviteLastOpened);
  const pairInviteCodeAccepting = useSelector(state => state.local.pairInviteCodeAccepting);
  const { invite: inviteOpened } = lib.useInviteCode(inviteLastOpened);
  const { invite: inviteAccepting } = lib.useInviteCode(pairInviteCodeAccepting);

  const { data: invitesInitial = [] } = api.useGetPairInvitesQuery(city?.id, { skip: !user || !city?.id });

  // console.log('DDDDD', { pool, invitesInitial, invitePressed });

  if (!pool) return null;

  const allTogether = [...invitesInitial, inviteOpened, inviteAccepting];
  const poolInvites1 = allTogether.filter(inv => inv?.pool_id === pool.id && inv.invite_type === lib.INVITE_TYPE.PAIR);
  const poolInvites = deduplicateInvites(poolInvites1);

  const invitesPendingReceived = poolInvites.filter(inv => inv.isFeatureActive && user && inv.user_from_id !== user.id);
  const invitePendingSent = poolInvites.find(inv => inv.isFeatureActive && user && inv.user_from_id === user.id);
  const inviteAccepted = poolInvites.find(inv => inv.isFeatureAccepted);
  // const inviteAccepting = pairInviteAccepting && poolInvites.find(inv => inv.id === pairInviteAccepting.id);

  // console.log('DDDDD2', { allTogether, poolInvites });
  return {
    invitesPendingReceived,
    invitePendingSent,
    // inviteAccepting,
    inviteAccepted,
  };
};
