import { lib } from '@tlsocial/common';

export const links = {
  HOME: '/',
  PRIVACY: '/privacy',
  TERMS: '/terms',
  JOIN: '/join',
  ABOUT: '/about',
  FAQ: '/faq',
  HOW: '/how-it-works',
  ETIQUETTE: '/etiquette',
  CITIES: '/locations',
  UNSUBSCRIBE: '/unsubscribe',
  CLUB: club => `/communities/${lib.toSlug(club.name, club.id)}`,
  CITY: cityKey => `/${cityKey}`,
  CITY_JOIN: city => `/${city.city_key}/join`,
  POOL: pool => `/${pool.city_key}/pools/${lib.toSlug(pool.title, pool.id)}`,
  EVENT: event => `/${event.city_key}/events/${lib.toSlug(event.title, event.id)}`,
  CLUBS: city => `/${city.city_key}/communities`,
  CITY_CLUB: (club, city) => `/${city.city_key}/communities/${lib.toSlug(club.name, club.id)}`,
  RESTAURANT: location => `/${location.city_key}/restaurants/${lib.toSlug(location.name, location.id)}`,
  CITY_RESTAURANTS: city => `/${city.city_key}/restaurants`,
  CITY_OTHER_CITIES: city => `/${city.city_key}/other-locations`,
  EVENTS: city => `/${city.city_key}/events`,
  CITY_HOST: city => `/${city.city_key}/host`,
  CITY_HOW: city => `/${city.city_key || 'home'}/how-it-works`,
  CITY_FAQ: city => `/${city?.city_key || 'home'}/faq`,
  CITY_ETIQUETTE: city => `/${city?.city_key || 'home'}/etiquette`,

  ADMIN: '/admin',
  ADMIN_LOGIN: '/admin/login',
  ADMIN_PROFILE_GLOBAL: userId => `/admin/global/users/${userId}`,
  ADMIN_PROFILE: (userId, cityKey) => `/admin/${cityKey}/users/${userId}`,
  ADMIN_PROFILE_REVIEWS: (userId, city) => `/admin/${city.city_key}/users/${userId}/reviews`,
  ADMIN_PROFILE_EVENTS: (userId, city) => `/admin/${city.city_key}/users/${userId}/events`,
  ADMIN_CITY: cityKey => `/admin/${cityKey}`,
  ADMIN_SEARCH: city => `/admin/${city.city_key}/users/search`,
  ADMIN_CHARTS: city => `/admin/${city.city_key}/charts`,
  ADMIN_CHARTS_ATTENDEES: city => `/admin/${city.city_key}/charts/attendees`,
  ADMIN_CHARTS_ATTENDEES_KEY: cityKey => `/admin/${cityKey}/charts/attendees`,
  ADMIN_CHARTS_MEMBERS: city => `/admin/${city.city_key}/charts/members`,
  ADMIN_CHARTS_REFERRALS: city => `/admin/${city.city_key}/charts/referrals`,
  ADMIN_CHARTS_RATINGS: city => `/admin/${city.city_key}/charts/event-ratings`,
  ADMIN_LOCATIONS_KEY: cityKey => `/admin/${cityKey}/locations`,
  ADMIN_LOCATIONS_ALL: cityKey => `/admin/${cityKey}/locations/all`,
  ADMIN_LOCATIONS_FEATURED: cityKey => `/admin/${cityKey}/locations/featured`,
  ADMIN_RESERVATIONS: cityKey => `/admin/${cityKey}/locations/reservations`,
  ADMIN_EVENTS: city => `/admin/${city.city_key}/events`,
  ADMIN_EVENTS_UPCOMING: city => `/admin/${city.city_key}/events/upcoming`,
  ADMIN_EVENTS_UPCOMING_KEY: cityKey => `/admin/${cityKey}/events/upcoming`,
  ADMIN_EVENTS_PAST: city => `/admin/${city.city_key}/events/past`,
  ADMIN_MATCHED_EVENTS: cityKey => `/admin/${cityKey}/events/matched`,
  ADMIN_EVENT: (event, city) => `/admin/${city.city_key}/events/${event.id}`,
  ADMIN_EVENT2: (eventId, city) => `/admin/${city.city_key}/events/${eventId}`,
  ADMIN_EVENT_LOCATIONS: (event, city) => `/admin/${city.city_key}/events/${event.id}/locations`,
  ADMIN_EVENT_ATTENDEES: (event, city) => `/admin/${city.city_key}/events/${event.id}/attendees`,
  ADMIN_EVENT_RELATIONS: (event, city) => `/admin/${city.city_key}/events/${event.id}/relations`,
  ADMIN_EVENT_REVIEWS: (eventId, city) => `/admin/${city.city_key}/events/${eventId}/reviews`,
  ADMIN_CLUBS: city => `/admin/${city.city_key}/pools/communities`,
  ADMIN_REVIEWS: city => `/admin/${city.city_key}/reviews`,
  ADMIN_POOLS: city => `/admin/${city.city_key}/pools`,
  ADMIN_POOLS_UPCOMING: city => `/admin/${city.city_key}/pools/upcoming`,
  ADMIN_POOLS_UPCOMING_KEY: cityKey => `/admin/${cityKey}/pools/upcoming`,
  ADMIN_POOLS_PAST: city => `/admin/${city.city_key}/pools/past`,
  ADMIN_POOL: (pool, city) => `/admin/${city.city_key}/pools/${pool.id}`,
  ADMIN_POOL2: (poolId, cityKey) => `/admin/${cityKey}/pools/${poolId}`,
  ADMIN_POOL_MATCHING: (pool, city) => `/admin/${city.city_key}/pools/${pool.id}/matching`,
  ADMIN_POOL_REGISTRATIONS: (pool, city) => `/admin/${city.city_key}/pools/${pool.id}/registrations`,
  ADMIN_POOL_ATTENDEES: (pool, city) => `/admin/${city.city_key}/pools/${pool.id}/attendees`,
  ADMIN_POOL_LOCATIONS: (pool, city) => `/admin/${city.city_key}/pools/${pool.id}/locations`,
  ADMIN_POOL_RELATIONS: (pool, city) => `/admin/${city.city_key}/pools/${pool.id}/relations`,
  ADMIN_POOL_RESERVATIONS: (pool, city) => `/admin/${city.city_key}/pools/${pool.id}/reservations`,
  ADMIN_POOL_EVENTS: (pool, city) => `/admin/${city.city_key}/pools/${pool.id}/events`,
  ADMIN_POOL_REVIEWS: (pool, city) => `/admin/${city.city_key}/pools/${pool.id}/reviews`,
  // ADMIN_POOL_EVENT: (pool, city, event) => `/admin/${city.city_key}/pools/${pool.id}/events/${event.id}`,
  // ADMIN_POOL_EVENT2: (poolId, city, event) => `/admin/${city.city_key}/pools/${poolId}/events/${event.id}`,
  ADMIN_MEMBERS_RECENT: city => `/admin/${city.city_key}/users`,
  ADMIN_UNRATED_MEMBERS: city => `/admin/${city.city_key}/users/unrated`,
  ADMIN_APPLICANTS: city => `/admin/${city.city_key}/users/applicants`,
  ADMIN_APPLICANTS_ALL: cityKey => `/admin/${cityKey}/users/applicants/all`,
  ADMIN_APPLICANTS_QUEUED_1: cityKey => `/admin/${cityKey}/users/applicants/queued1`,
  ADMIN_APPLICANTS_QUEUED_2: cityKey => `/admin/${cityKey}/users/applicants/queued2`,
  ADMIN_LEADERBOARDS: city => `/admin/${city.city_key}/users/leaderboards`,
  ADMIN_LEADERBOARD: (cityKey, leaderboardType) => `/admin/${cityKey}/users/leaderboards/${leaderboardType}`,
  ADMIN_LOGS: (objType, objId) => `/admin/logs/${objType}/${objId}`,
};

export const linksTo = {
  CITY: object => ({ to: links.CITY(object.city_key), state: { object } }),
  POOL: object => ({ to: links.POOL(object), state: { object } }),
  EVENT: object => ({ to: links.EVENT(object), state: { object } }),
  LOCATION: object => ({ to: links.RESTAURANT(object), state: { object } }),
  ADMIN_POOL: (pool, city) => ({ to: links.ADMIN_POOL(pool, city) }),
  ADMIN_POOL2: (poolId, cityKey) => ({ to: links.ADMIN_POOL2(poolId, cityKey) }),
  ADMIN_EVENT: (event, city) => ({ to: links.ADMIN_EVENT(event, city) }),
};
