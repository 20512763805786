import { isAndroid, isProduction, isReactNative } from '../platform/platform';

// const devBaseUrl = isAndroid ? 'http://10.21.3.32:8000' : 'http://10.21.3.32:8000';
const devBaseUrl = isAndroid ? 'http://10.0.2.2:8000' : 'http://127.0.0.1:8000';

// ANDROID (device)
// NOTE. to test on local android device set API_URL to the IP in Network Settings -> Wifi

// IOS (device)
// Run django server like so: ./manage.py runserver 0.0.0.0:8000

const devConfig = {
  IS_REACT_NATIVE: isReactNative,
  IS_PRODUCTION: false,
  API_URL: `${devBaseUrl}/api`,
  MEDIA_URL: `${devBaseUrl}/media`,
  STATIC_URL: `${devBaseUrl}/static`,
  DJANGO_ADMIN_URL: `${devBaseUrl}/admin`,
  WEB_URL: 'http://localhost:3000',
  POSTHOG_HOST: 'https://us.i.posthog.com',
  POSTHOG_PROJECT_GUEST_API_KEY: 'phc_lnjG1kZcXBX6Dx2CAZerv2FFYd5Y4TEjK0LR1mTDLfE',
  POSTHOG_PROJECT_ADMIN_API_KEY: 'phc_HereJ5pJ58ljROBnyO7bYxqyCcGLltVhQ4B1podsU4Q',
  TWITTER_PIXEL_ID: '',
  META_PIXEL_ID: '',
};

const prodConfig = {
  IS_REACT_NATIVE: isReactNative,
  IS_PRODUCTION: true,
  API_URL: 'https://api.socialtable.app/api',
  MEDIA_URL: `https://tlsocial.s3.amazonaws.com/m`,
  STATIC_URL: `https://tlsocial.s3.amazonaws.com/s`,
  DJANGO_ADMIN_URL: 'https://chef.socialtable.app/admin',
  WEB_URL: 'https://socialtable.app',
  POSTHOG_HOST: 'https://us.i.posthog.com',
  POSTHOG_PROJECT_GUEST_API_KEY: 'phc_fFwZzPrnjhfeirccHiES7rsVIQZuBJ6yljZBBuT6Bol',
  POSTHOG_PROJECT_ADMIN_API_KEY: 'phc_NiBvY0ofey7z3E5jbcrYanVP1XhhtVg6RZwOrHuVud3',
  TWITTER_PIXEL_ID: 'ojhji',
  META_PIXEL_ID: '913165627189809',
};

export const env = isProduction ? prodConfig : devConfig;
