import { createSlice } from '@reduxjs/toolkit';
import { apiAuth } from '../api';

let initialState = {
  appToken: null,
  posthogAnonymousId: null,
  isStreamClientReady: false,
  isStreamClientConnected: false,
  isStreamClientLoading: false,
  unreadChatCount: 0,
  language: null,
  bools: {
    // howGroupsWork: false,
    seenRatingIntro: false,
  },
  bellsSeen: {},
  screens: {},
  editTopicsNeedsRefresh: false,
  cityLastSeenNewMembers: {},
  cityLastSeenUpdates: {},
  calendarIds: {},
  lastCodepushSync: null,
  inviteLastOpened: null,
  appInviteCodeAccepting: null,
  pairInviteCodeAccepting: null,
  utm: null,
  reduxPosthogFlags: null,
};
// c.ONBOARDING_SCREENS_APPLY.forEach(screenName => (initialState.screens[screenName] = false));

const setToken = (state, { payload }) => {
  state.appToken = payload.key;
};

export const localSlice = createSlice({
  name: 'local',
  initialState,
  reducers: {
    resetLocalState: (state, action) => {
      return {
        ...initialState,
        appToken: state.appToken,
      };
    },
    resetAllState: state => {
      // We handle this in store.js
    },
    setAppToken: (state, { payload }) => {
      state.appToken = payload.key;
    },
    setPosthogAnonymousId: (state, { payload }) => {
      state.anonymousId = payload;
    },
    setIsStreamClientReady: (state, { payload }) => {
      state.isStreamClientReady = payload;
    },
    setIsStreamClientConnected: (state, { payload }) => {
      state.isStreamClientConnected = payload;
    },
    setIsStreamClientLoading: (state, { payload }) => {
      state.isStreamClientLoading = payload;
    },
    setUnreadChatCount: (state, { payload }) => {
      state.unreadChatCount = payload;
    },
    setBool: (state, { payload }) => {
      state.bools[payload.key] = payload.value;
    },
    setBellSeen: (state, action) => {
      state.bellsSeen[action.payload] = true;
    },
    setAllBellsSeen: (state, action) => {
      state.bellsSeen = action.payload;
    },
    setAppLang: (state, action) => {
      state.language = action.payload;
    },
    setScreenDone: (state, action) => {
      state.screens[action.payload] = true;
    },
    setEditTopicsNeedsRefresh: (state, action) => {
      state.editTopicsNeedsRefresh = action.payload;
    },
    setLastSeenNewMembers: (state, action) => {
      const payload = action.payload;
      state[payload.cityId] = {
        ...state[payload.cityId],
        lastSeenNewMembers: payload.lastSeen,
      };
    },
    setCityLastSeenUpdates: (state, action) => {
      const payload = action.payload;
      if (!state.cityLastSeenUpdates) state.cityLastSeenUpdates = {};
      state.cityLastSeenUpdates[payload.cityId] = payload.lastSeen;
    },
    setInviteCodeOpened: (state, action) => {
      state.inviteLastOpened = action.payload;
    },
    setAppInviteCodeAccepting: (state, action) => {
      state.appInviteCodeAccepting = action.payload;
    },
    setPairInviteCodeAccepting: (state, action) => {
      state.pairInviteCodeAccepting = action.payload;
    },
    setCalendarId: (state, action) => {
      const { reduxId, calEventId } = action.payload;
      if (!state.calendarIds) {
        state.calendarIds = {};
      }
      state.calendarIds[reduxId] = calEventId;
    },
    setLastCodepushSync: (state, action) => {
      state.lastCodepushSync = action.payload;
    },
    setUtm: (state, action) => {
      state.utm = action.payload;
    },
    setReduxPosthogFlags: (state, action) => {
      state.reduxPosthogFlags = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addMatcher(apiAuth.endpoints.testLoginAsNewUser.matchFulfilled, setToken)
      .addMatcher(apiAuth.endpoints.loginWithKey.matchFulfilled, setToken)
      .addMatcher(apiAuth.endpoints.loginWithUsername.matchFulfilled, setToken)
      .addMatcher(apiAuth.endpoints.loginWithPhone.matchFulfilled, setToken)
      .addMatcher(apiAuth.endpoints.loginWithWhatsapp.matchFulfilled, setToken);
  },
});

// Action creators are generated for each case reducer function
export const {
  resetAllState,
  setAppToken,
  setPosthogAnonymousId,
  setIsStreamClientReady,
  setIsStreamClientConnected,
  setIsStreamClientLoading,
  setUnreadChatCount,
  setBool,
  setBellSeen,
  setAllBellsSeen,
  setAppLang,
  resetLocalState,
  setScreenDone,
  setEditTopicsNeedsRefresh,
  setLastSeenNewMembers,
  setCityLastSeenUpdates,
  setInviteCodeOpened,
  setAppInviteCodeAccepting,
  setPairInviteCodeAccepting,
  setCalendarId,
  setLastCodepushSync,
  setUtm,
  setReduxPosthogFlags,
} = localSlice.actions;

export default localSlice.reducer;
