import { usePageview } from '@/hooks/usePageview';
import { lib } from '@tlsocial/common';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { useEffect } from 'react';

const Pixels = () => {
  const router = useRouter();

  const handlePageView = () => {
    // console.log('PAGE view');
    // Meta Pixel
    if (lib.env.META_PIXEL_ID) {
      window.fbq('track', 'PageView');
    }
  };

  usePageview(handlePageView);

  return (
    <>
      <Script id="twitter-pixel" strategy="afterInteractive">
        {`
        !function(e,t,n,s,u,a){e.twq||(s=e.twq=function(){s.exe?s.exe.apply(s,arguments):s.queue.push(arguments);
        },s.version='1.1',s.queue=[],u=t.createElement(n),u.async=!0,u.src='https://static.ads-twitter.com/uwt.js',
        a=t.getElementsByTagName(n)[0],a.parentNode.insertBefore(u,a))}(window,document,'script');
        twq('config','${lib.env.TWITTER_PIXEL_ID}');
      `}
      </Script>

      {lib.env.META_PIXEL_ID && (
        <Script id="meta-pixel" strategy="afterInteractive">
          {`
        !function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '${lib.env.META_PIXEL_ID}');
      `}
        </Script>
      )}
    </>
  );
};

export default Pixels;
