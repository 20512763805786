import { tags } from './rtkTags';
import { apiSlice } from './apiSlice';
import { arrayToObject, arrayToObjectList } from '../library';
import { constructTestimonialsFromAPI } from './apiConstruct';

export const apiPools = apiSlice.injectEndpoints({
  endpoints: build => ({
    getPool: build.query({
      query: id => `/pools/${id}`,
    }),
    getPools: build.query({
      query: cityId => `/pools/city/${cityId}/data`,
      providesTags: [tags.POOLS_UPCOMING, tags.CITY],
    }),
    getPoolRegistrations: build.query({
      query: cityId => `/pools/registrations/city/${cityId}`,
      transformResponse: regs => arrayToObject(regs, 'pool_id'),
      providesTags: [tags.POOL_REGISTRATIONS],
    }),
    getSeriesTestimonials: build.query({
      query: ({ seriesId, cityId }) => `/series/${seriesId}/testimonials/${cityId}`,
      transformResponse: constructTestimonialsFromAPI,
    }),
    getSeriesPhotos: build.query({
      query: ({ seriesId, cityId }) => `/series/${seriesId}/photos/${cityId}`,
    }),
    updatePoolRegistration: build.mutation({
      query: body => ({ url: `/pools/${body.pool_id}/update_registration`, method: 'PUT', body }),
    }),
    confirmPoolRegistration: build.mutation({
      query: body => ({ url: `/pools/${body.pool_id}/confirm_registration`, method: 'PUT', body }),
      invalidatesTags: [tags.POOL_REGISTRATIONS, tags.USER],
    }),
    cancelPoolRegistration: build.mutation({
      query: body => ({ url: `/pools/${body.pool_id}/cancel_registration`, method: 'PUT', body }),
      invalidatesTags: [tags.POOL_REGISTRATIONS, tags.USER],
    }),
    joinPoolWaitlist: build.mutation({
      query: body => ({ url: `/pools/${body.pool_id}/join_waitlist`, method: 'PUT', body }),
      invalidatesTags: [tags.POOL_REGISTRATIONS],
    }),
    cancelPoolWaitlist: build.mutation({
      query: body => ({ url: `/pools/${body.pool_id}/cancel_waitlist`, method: 'PUT', body }),
      invalidatesTags: [tags.POOL_REGISTRATIONS],
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetPoolQuery,
  useGetPoolsQuery,
  // useGetPools2Query,
  useGetPoolRegistrationsQuery,
  useGetSeriesTestimonialsQuery,
  useGetSeriesPhotosQuery,
  useUpdatePoolRegistrationMutation,
  useConfirmPoolRegistrationMutation,
  useCancelPoolRegistrationMutation,
  useJoinPoolWaitlistMutation,
  useCancelPoolWaitlistMutation,
} = apiPools;
