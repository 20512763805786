export const colors = {
  white: '#ffffff',
  black: '#000000',
  topBg: '#ffffff',
  mainBg: '#ffffff',
  mainText: '#282828',
  blackBg: '#000000',
  darkBg: '#211e1e',
  darkBgButton: '#332929',
  darkBg2: '#343232',
  darkBgText: '#ffe8e8',
  alpha0: 'rgba(0, 0, 0, 0)',
  alpha50: 'rgba(0, 0, 0, 0.04)',
  alpha100: 'rgba(0, 0, 0, 0.06)',
  alpha200: 'rgba(0, 0, 0, 0.08)',
  alpha300: 'rgba(0, 0, 0, 0.15)',
  alpha400: 'rgba(0, 0, 0, 0.24)',
  alpha500: 'rgba(0, 0, 0, 0.36)',
  alpha600: 'rgba(0, 0, 0, 0.48)',
  alpha700: 'rgba(0, 0, 0, 0.64)',
  alpha800: 'rgba(0, 0, 0, 0.80)',
  alpha900: 'rgba(0, 0, 0, 0.92)',
  whiteAlpha50: 'rgba(255, 255, 255, 0.04)',
  whiteAlpha100: 'rgba(255, 255, 255, 0.07)',
  whiteAlpha200: 'rgba(255, 255, 255, 0.10)',
  whiteAlpha300: 'rgba(255, 255, 255, 0.15)',
  whiteAlpha400: 'rgba(255, 255, 255, 0.24)',
  whiteAlpha500: 'rgba(255, 255, 255, 0.36)',
  whiteAlpha600: 'rgba(255, 255, 255, 0.48)',
  whiteAlpha700: 'rgba(255, 255, 255, 0.64)',
  whiteAlpha800: 'rgba(255, 255, 255, 0.80)',
  whiteAlpha900: 'rgba(255, 255, 255, 0.92)',
  primary50: '#ffedeb',
  primary100: '#ffdad6',
  primary200: '#ffa6a3',
  primary300: '#ff757a',
  primary400: '#f54755',
  primary500: '#e82a40',
  primary600: '#ce1b36',
  primary700: '#b31d36',
  primary800: '#8f152c',
  primary900: '#711225',
  // primary50: '#fcf2f4',
  // primary100: '#d9edfa',
  // primary200: '#b5dbf5',
  // primary300: '#8ac5ed',
  // primary400: '#6dbaed',
  // primary500: '#429bd6',
  // primary600: '#3594d4',
  // primary700: '#2272a8',
  // primary800: '#1d618f',
  // primary900: '#154769',
  bright300: '#ff909f',
  bright400: '#ff586b',
  bright500: '#ff4159',
  bright600: '#d30020',
  bright700: '#9f0018',
  secondary100: '#C6F6D4',
  statusLight: {
    success: '#b6e898',
    warning: '#ffd07e',
    error: '#ffd07e',
    info: '#ffdad6',
  },
  statusDark: {
    success: '#00990b',
    warning: '#d31e00',
    error: '#d31e00',
    info: '#711225',
  },
  errorText: '#d31e00',
  light1: '#f4f2f2',
  light2: '#e8e3e3',
  // light2: '#f7dede',
  dark1: '#0f0a0a',
  dark2: '#1A1A1A',
  // Badge levels
  memberLevelGuest: '#b0d600',
  memberLevelMember: '#e8465b',
  memberLevelHost: '#6f00cd',
  memberLevelAmbassador: '#cf30d8',
};
